import { DataGrid, GridColumns } from '@material-ui/data-grid';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { formatCurrencyAsText } from '~/utils/functions';
import { FaTrash } from 'react-icons/fa';
import { GoPencil } from 'react-icons/go';
import { useEntradaNFE } from '~/pages/EntradaNFE/EntradaNFEContext';
import { Parcela } from '~/pages/EntradaNFE/protocols';
import clearForm from '../FormParcelas/clearForm.json';
import { ButtonRow, TableContainer } from './styles';
import Swal from 'sweetalert2';
import { deleteItens } from '~/utils/masterDetail';
import { createDateWithoutTimezone } from '~/utils/createDateWithoutTimezone ';

export const TableFinanceiro: React.FC = () => {
  const {
    finalizadoras,
    condicoesPagamento,

    formParcela,
    canUpdate,
    setUuidParcela,
    parcelas,
    setParcelas,
    masterDetail,
    setMasterDetail,
  } = useEntradaNFE();

  const { setValue, reset } = formParcela;

  const columns: GridColumns = [
    {
      field: 'id',
      headerName: 'Id',
      hide: true,
      width: 100,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'num_registro',
      headerName: 'Num. Registro',
      flex: 1,
      hide: true,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'num_condicao',
      headerName: 'Prazo',
      width: 80,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'cod_condicao',
      headerName: 'Condição',
      width: 300,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params: { row: any }) => {
        const { row } = params;
        const condicaoPagamento = condicoesPagamento.find(
          (reg) => reg.value === row.cod_condicao,
        );
        return (
          <>
            <span>{condicaoPagamento?.label}</span>
          </>
        );
      },
    },
    {
      field: 'dta_vencimento',
      headerName: 'Vencimento',
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params: { row: any }) => {
        const { row } = params;

        return (
          <>
            {moment(createDateWithoutTimezone(row.dta_vencimento)).format(
              'DD/MM/YYYY',
            )}
          </>
        );
      },
    },
    {
      field: 'val_parcela',
      headerName: 'Valor',
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params: { row: any }) => {
        const { row } = params;
        return (
          <>
            <span>{formatCurrencyAsText(row.val_parcela)}</span>
          </>
        );
      },
    },
    {
      field: 'des_finalizadora',
      headerName: 'Finalizadora',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: '',
      headerName: 'Ações',
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: { row: any }) => {
        const { row } = params;
        return (
          <>
            <ButtonRow
              type="button"
              onClick={() => onEditItem(row.uuid, row?.cod_parcela)}
              isBlocked={!canUpdate}
              disabled={!canUpdate}
              title="Editar"
            >
              <GoPencil size={20} style={{ color: '#72ab90' }} />
            </ButtonRow>
            <ButtonRow
              type="button"
              onClick={() => onRemoveItem(row.uuid)}
              isBlocked={!canUpdate}
              disabled={!canUpdate}
              title="Remover"
            >
              <FaTrash size={20} style={{ color: '#e63c3c' }} />
            </ButtonRow>
          </>
        );
      },
    },
  ];

  const onEditItem = (uuid: string, cod_parcela: number | undefined) => {
    setValue('condicao_update', true);
    const reg = parcelas.find((parcela: Parcela) => parcela.uuid === uuid);

    if (reg) {
      setValue('condicao', {
        label: reg.des_condicao,
        value: reg.cod_condicao,
      });
      setValue('finalizadora', {
        label: reg.des_finalizadora,
        value: reg.cod_finalizadora,
      });
      setValue('dta_vencimento', reg.dta_vencimento.substring(0, 10));
      setValue('val_parcela', formatCurrencyAsText(reg.val_parcela));
      setValue('num_condicao', reg.num_condicao);
      setValue('num_registro', reg.num_registro);
      setUuidParcela({ uuid, cod_parcela });
    }
  };

  const onRemoveItem = (uuid: string) => {
    Swal.fire({
      title: ``,
      text: 'Deseja realmente remover essa parcela?',

      showCancelButton: true,
      confirmButtonColor: '#07289e',
      cancelButtonColor: '#ff7b7b',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const parcelasDetail: any[] = await deleteItens(
          'parcelas',
          uuid,
          masterDetail,
          setMasterDetail,
        );

        setParcelas(parcelasDetail);
        handleClearForm;
      }
    });
  };

  const handleClearForm = () => {
    reset(clearForm);
    setUuidParcela(undefined);
  };
  return (
    <TableContainer>
      <DataGrid
        rowsPerPageOptions={[5]}
        rows={parcelas}
        columns={columns}
        pageSize={5}
        disableColumnSelector
        disableColumnFilter
        disableSelectionOnClick
        localeText={{
          noRowsLabel: 'Nenhum registro encontrado...',
          columnMenuLabel: 'Menu',
          columnMenuFilter: 'Filtrar',
          columnMenuHideColumn: 'Esconder',
          columnMenuUnsort: 'Não ordenar',
          columnMenuSortAsc: 'Ordernar ASC',
          columnMenuSortDesc: 'Ordernar DESC',
          columnMenuShowColumns: 'Mostrar columnas',
        }}
      />
    </TableContainer>
  );
};
