import api from '~/services/api';

const fetchConditions = async (
  fornecedor: number | string,
  tipo_condicao: number | string,
): Promise<any> => {
  const { data } = await api.get(
    `/nf-financeiro/parceiro/${fornecedor}/tipo-condicao/${tipo_condicao}`,
  );

  return data;
};

const fetchPerfilConditions = async (
  cod_perfil: number | string,
): Promise<any> => {
  const { data } = await api.get(`/busca-financeiro/${cod_perfil}`);

  return data;
};
export const conditions = { fetchConditions, fetchPerfilConditions };
